import JobList from "../components/home/JobList.jsx";

import React from "react";

function Home() {
  return (
    <div>
      <JobList />
    </div>
  );
}

export default Home;
