import MessageSheet from "../components/message/MessageSheet";

function Message() {
  return (
    <div>
      <MessageSheet />
    </div>
  );
}

export default Message;
