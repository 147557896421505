import { useState } from "react";
import styled from "styled-components";
import { apiUrl } from "../../common/Constant";
import MsgPopup from "./MsgPopup";

function MessageSheet() {
  const [message, setMessage] = useState("");
  const [contactType, setContactType] = useState("email");
  const [contact, setContact] = useState("");

  const [openPopup, setOpenPopup] = useState(false);
  const closeModal = () => setOpenPopup(false);
  const [popMessage, setPopMessage] = useState("");

  const handleClick = async () => {
    if (message.trim().length == 0) {
      alert("请输入您的留言");
      return;
    }
    try {
      const response = await fetch(apiUrl.MESSAGE_SAVE, {
        method: "POST",
        body: JSON.stringify({
          contactType: contactType,
          contact: contact,
          message: message,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("调用后端message api的返回结果：" + result);
      if (!result.success) {
        console.error(result.message);
        alert("sorry something went wrong..");
      } else {
        clearAll();
        setPopMessage("感谢反馈！！");
        setOpenPopup(true);
      }
    } catch (err) {
      console.error(err);
      alert("sorry something went wrong..");
    }
  };

  const clearAll = () => {
    setMessage("");
    setContactType("email");
    setContact("");
  };

  return (
    <Wrapper>
      {MsgPopup(openPopup, closeModal, popMessage)}
      <MessageWrapper>
        <Label>
          * 留言：
          <MessageInput
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Label>
      </MessageWrapper>
      <ContactWrapper>
        <Label>您的联系方式：</Label>
        <ContactTypeSelect
          value={contactType}
          onChange={(e) => setContactType(e.target.value)}
        >
          <Option value="email">邮件</Option>
          <Option value="wechat">微信</Option>
          <Option value="phone">电话</Option>
          <Option value="other">其他</Option>
        </ContactTypeSelect>
        <ContactInput
          type="text"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        ></ContactInput>
      </ContactWrapper>
      <ButtonWrapper>
        <Submit onClick={handleClick}>提交</Submit>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default MessageSheet;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 50rem;
`;

const MessageWrapper = styled.form`
  margin-top: 3rem;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
`;

const MessageInput = styled.textarea`
  margin-top: 0.5rem;
  width: 50rem;
  height: 10rem;
  padding: 0.5rem;
  line-height: 1.4rem;
  font-size: 1rem;
`;

const ContactWrapper = styled.div`
  margin-top: 1.5rem;
`;

const ContactTypeSelect = styled.select`
  width: 5rem;
  height: 1.5rem;
  margin-right: 1rem;
  font-size: 1rem;
`;

const ContactInput = styled.input`
  width: 15rem;
  font-size: 1rem;
`;

const Option = styled.option`
  font-size: 1rem;
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const Submit = styled.button`
  width: 4rem;
  font-size: 1rem;
  padding: 0.1rem;
  text-align: center;
  &:hover {
    background-color: #c0c0c0;
    cursor: pointer;
  }
`;
