import About from "../components/about/About.jsx";

import React from "react";

function AboutPage() {
  return (
    <div>
      <About />
    </div>
  );
}

export default AboutPage;
