import { useState } from "react";
import { NavLink as MyNavLink } from "react-router-dom";
import styled from "styled-components";

function Navbar() {
  return (
    <Wrapper>
      <Container>
        <Logo>
          <Img
            src={process.env.PUBLIC_URL + "/logos/transparent_small.png"}
            alt="轻松游牧"
          />
          <Title>
            <Name>轻松游牧</Name>
            <Slogon>Easy Nomad</Slogon>
          </Title>
        </Logo>
        <Nav>
          <Ul>
            <Li>
              <NavLink to="/company">发现远程公司</NavLink>
            </Li>
          </Ul>
          <Ul showup="550">
            <Li>
              <NavLink to="/about">关于本站</NavLink>
            </Li>
            <Li>
              <NavLink to="/message">给作者留言</NavLink>
            </Li>
          </Ul>
        </Nav>
      </Container>
    </Wrapper>
  );
}

export default Navbar;

const Wrapper = styled.nav`
  width: 100%;
  display: flex;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dadada;
`;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 3.75rem;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 0.75rem;
`;

const Logo = styled(MyNavLink).attrs({ to: "/" })`
  flex: none;
  display: flex;
  align-items: center;
  color: var(--text-0);
  text-decoration: none;
  padding-bottom: 0.25rem;
  padding-left: 0.625rem;
`;

const Title = styled.div`
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
  height: 1.625rem;
`;

const Name = styled.span`
  color: var(--text-0);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
`;

const Slogon = styled.span`
  color: var(--text-2);
  font-size: 0.75rem;
  line-height: 0.75rem;
`;

const Nav = styled.nav`
  flex: 1;
  display: flex;
  padding-right: 2rem;
  justify-content: space-between;
`;

const Li = styled.li`
  list-style: none;
`;

const Ul = styled.ul`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0;
  margin: 0;

  ${(props) => {
    if (!("showup" in props)) return;
    return `
      @media only screen and (max-width: ${props.showup}px) {
        display: none;
      }
    `;
  }}
`;

const Img = styled.img`
  padding-top: 0.25rem;
  height: 2rem;
  width: 2rem;
`;

const NavLink = styled(MyNavLink)`
  text-decoration: none;
  color: black;
  font-size: 1.125rem;
  padding: 0.5rem 1.25rem;
  &:hover {
    border: darkmagenta;
    border-radius: 2px;
    font-weight: bolder;
  }
  &.active {
    // color: var(--text-0);
    // border-radius: 6px;
    // background-color: #f9fafb;
  }
`;
