import styled from "styled-components";

function About() {
  return (
    <Wrapper>
      <Content>
        <h1>关于远程岗位</h1>
        <p>本站的远程岗位均收集于各个论坛和社交媒体，目前的来源主要有：</p>
        <ul>
          <li>
            <A href="http://eleduck.com/">电鸭</A>
          </li>
          <li>
            <A href="https://v2ex.com/?tab=jobs">V2EX</A>
          </li>
          <li>
            <A href="https://news.ycombinator.com/">Hacker News</A>
          </li>
          <li>
            <A href="https://www.linkedin.com/feed/">Linkedin</A>
          </li>
        </ul>
        <p>如有侵权，请直接给作者留言。</p>
        <p>如果你有好的远程岗位信息渠道，也欢迎向我推荐。</p>
        <p>
          论坛上信息较杂，我虽然已经做了一遍过滤，但难免有漏网之鱼，
          <b>
            请大家在岗位投递时擦亮眼睛，谨防上当受骗！并仔细衡量岗位是否有法律风险！！
          </b>
        </p>
        <h1>关于远程公司</h1>
        <p>
          本站的远程公司均是我收集的<b>支持国内远程</b>
          的公司，可以直接跳转到招聘列表页进行投递。远程公司不定期更新。
        </p>
        <p>同样，如果你有远程公司推荐，欢迎给我留言。</p>
        <Xhs>分享远程经验，助力远程工作</Xhs>
        <Xhs>欢迎关注作者小红书：老J的云端机会</Xhs>
        <h1>特别感谢</h1>
        <p>
          感谢@
          <a href="https://iwfan.site/" target="_blank">
            Zi莱卷
          </a>
          帮忙修复网站的响应式问题，现在移动端也能方便地查看招聘信息了！
        </p>
      </Content>
    </Wrapper>
  );
}

export default About;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 70rem;
`;

const Content = styled.div`
  padding: 0rem 10rem;
`;

const A = styled.a`
  line-height: 1.6rem;
  text-decoration: none;
`;

const Xhs = styled.h2`
  margin-top: 0px;
  font-size: 1.8rem;
  box-sizing: border-box;
  min-width: 0px;
  line-height: 1.2;
  margin: 0px;
  background: linear-gradient(
    110.78deg,
    rgb(118, 230, 80) -1.13%,
    rgb(249, 214, 73) 15.22%,
    rgb(240, 142, 53) 32.09%,
    rgb(236, 81, 87) 48.96%,
    rgb(255, 24, 189) 67.94%,
    rgb(26, 75, 255) 85.34%,
    rgb(98, 216, 249) 99.57%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;
