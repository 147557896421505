import React from "react";
import Popup from "reactjs-popup";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import "../../iconfont/iconfont.js";

function JobDetail(job, open, closeModal) {
  return (
    <div>
      <StyledPopup open={open} closeOnDocumentClick onClose={closeModal} modal>
        <DetailWrapper>
          <Left>
            <Title>{job.postTitle}</Title>
            <DescInfo>
              <ReactMarkdown>{job.descContent}</ReactMarkdown>
            </DescInfo>
          </Left>
          <Right>
            {!isEmpty(job.salaryTxt) && (
              <Info>
                <Icon aria-hidden="true">
                  <use xlinkHref="#icon-qiandai"></use>
                </Icon>
                {job.salaryTxt}
              </Info>
            )}
            {!isEmpty(job.contactTxt) && (
              <Info>
                <Icon aria-hidden="true">
                  <use xlinkHref="#icon-lianxifangshi"></use>
                </Icon>
                {job.contactTxt}
              </Info>
            )}
            {!isEmpty(job.companyTxt) && (
              <Info>
                <Icon aria-hidden="true">
                  <use xlinkHref="#icon-gongsi"></use>
                </Icon>
                {job.companyTxt}
              </Info>
            )}
            {!isEmpty(job.jobTypeTxt) && (
              <Info>
                <Icon aria-hidden="true">
                  <use xlinkHref="#icon-yuanchengjiaoyu"></use>
                </Icon>
                {job.jobTypeTxt}
              </Info>
            )}
            {!isEmpty(job.source) && (
              <Info>
                <Icon aria-hidden="true">
                  <use xlinkHref="#icon-sousu"></use>
                </Icon>
                {job.source}
              </Info>
            )}
            <RefButton onClick={() => jumpToSource(job.url)}>
              点击前往原帖
            </RefButton>
          </Right>
          <Close onClick={closeModal}>&times;</Close>
        </DetailWrapper>
      </StyledPopup>
    </div>
  );
}

function jumpToSource(url) {
  const w = window.open("about:blank");
  w.location.href = url;
}

function isEmpty(str) {
  if (typeof str == "undefined" || str == null || str == "") {
    return true;
  } else {
    return false;
  }
}

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }
  &-content {
    width: 100%;
    max-width: 65rem;
    box-sizing: border-box;
    @media only screen and (max-width: 750px) {
      width: 90%;
    }
  }
`;

const DetailWrapper = styled.div`
  margin-top: 0vh;
  margin-left: -0.5rem;
  height: 80vh;
  width: 100%;
  max-width: 65rem;
  background-color: white;
  border-style: solid;
  border-radius: 1%;
  border-color: #c0c0c0;
  border-width: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0rem 0rem 1.5rem 1.5rem;
  display: flex;
  z-index: 100;
`;

const Left = styled.div`
  width: 74%;
`;

const Title = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 0.8rem;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom-style: solid;
  border-bottom-color: #c0c0c0;
  border-bottom-width: 1px;
`;

const DescInfo = styled.div`
  margin-top: 0.5rem;
  line-height: 1.7rem;
  height: 69vh;
  overflow: auto;
`;

const Right = styled.div`
  margin-top: 5rem;
  width: 19%;
  padding-left: 3%;
`;

const Info = styled.div`
  overflow: hidden;
  word-break: break-all;
  height: auto;
  margin-bottom: 0.8rem;
`;

const Icon = styled.svg`
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  padding-right: 0.5rem;
`;

const RefButton = styled.button`
  position: absolute;
  bottom: 1.5rem;
  background-color: #f4f4f4;
  border-width: 1px;
  border-radius: 0.4rem;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  &:hover {
    background-color: white;
    cursor: pointer;
  }
`;

const Close = styled.div`
  margin-top: 1rem;
  height: 1.2rem;
  width: 1.2rem;
  border-style: solid;
  border-radius: 50%;
  border-color: #c0c0c0;
  border-width: 1.5px;
  text-align: center;
  align-content: center;
  align-items: center;
  color: #969696;
  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;

export default JobDetail;
