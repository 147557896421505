import React from "react";
import { useMemo, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { apiUrl } from "../../common/Constant";
import ReactPaginate from "react-paginate";
import useRequest from "../../hooks/useRequest";
import "./pagination.css";

function CompanyList() {
  const [companies, setCompanies] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [companyCnt, setCompanyCnt] = useState(100);
  const [page, setPage] = useState(1);
  const perPage = 9;
  const pageCount = Math.ceil(companyCnt / perPage);

  const companyListUrl = useMemo(() => {
    const selectedIndustriesStr = selectedIndustries.join("--");
    return `${apiUrl.COMPANY_LIST}page=${page}&industry=${selectedIndustriesStr}`;
  }, [page, selectedIndustries]);

  const { isLoading: industryLoading } = useRequest(
    apiUrl.COMPANY_INDUSTRIES,
    ({ data }) => {
      const industryArr = data.data
        .split("--")
        .map((item) => ({ value: item, label: item }));
      setIndustries(industryArr);
    }
  );

  const { isLoading: companyLoading } = useRequest(
    companyListUrl,
    ({ data }) => {
      setCompanyCnt(data.totalRecordCnt);
      setCompanies(data.data);
    }
  );

  const handleSelectChange = (e) => {
    setSelectedIndustries(e.map((item) => item.value));
    setPage(1);
  };

  function jumpToSource(url) {
    const w = window.open("about:blank");
    w.location.href = url;
  }

  const handlePageClick = ({ selected }) => {
    setPage(selected + 1);
  };

  // const colourStyles = {
  //   container: (styles) => ({
  //     ...styles,
  //     width: "100%",
  //     maxWidth: "20rem",
  //     marginBottom: "16px",
  //   }),
  //   control: (styles) => ({
  //     ...styles,
  //     borderRadius: "8px",
  //     border: "1px solid var(--border)",
  //     ":hover": {
  //       borderColor: "var(--border-active)",
  //       boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  //     },
  //   }),
  //   indicatorSeparator: (styles) => ({
  //     ...styles,
  //     backgroundColor: "var(--border)",
  //   }),
  // };

  return (
    <Wrapper>
      <FilterWrapper>
        <StyledSelect
          onChange={handleSelectChange}
          placeholder="请选择行业"
          options={industries}
          isLoading={industryLoading}
          // styles={colourStyles}
          isMulti
        />
      </FilterWrapper>
      <CompaniesWrapper>
        {companies.map((com, index) => {
          return (
            <CardWapper key={index}>
              <CardHead>
                <HeadImageWapper>
                  <HeadImage
                    src={"./companyimgs/" + com.logoName + ".png"}
                    alt={com.CompanyName}
                  />
                </HeadImageWapper>
                <HeadText>
                  <ComName>{com.companyName}</ComName>
                  <ComSummaries>
                    <FirstSummary>{com.companyIndustry}</FirstSummary>
                    <Summary>{com.headquarter}</Summary>
                    <Summary>{com.scale}</Summary>
                  </ComSummaries>
                </HeadText>
              </CardHead>
              <CardContent>
                <ComIntro>{com.introduction}</ComIntro>
              </CardContent>

              <CardBottom>
                <HomeButton onClick={() => jumpToSource(com.homeUrl)}>
                  官网首页
                </HomeButton>
                <CareerButton onClick={() => jumpToSource(com.careerUrl)}>
                  <Dot />
                  在招岗位列表
                </CareerButton>
              </CardBottom>
            </CardWapper>
          );
        })}
      </CompaniesWrapper>
      <PaginateWrapper>
        <ReactPaginate
          activeClassName={"item active-page "}
          breakClassName={"item break-me "}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          nextClassName={"item next"}
          pageClassName={"item pagination-page "}
          previousClassName={"item previous"}
          previousLabel={"上一页"}
          nextLabel={"下一页"}
          breakLabel={"..."}
          pageRangeDisplayed={4}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          onPageChange={handlePageClick}
        />
      </PaginateWrapper>
    </Wrapper>
  );
}

export default CompanyList;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 70rem;
`;

const FilterWrapper = styled.div`
  width: 100%;
  max-width: 70rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
`;

const StyledSelect = styled(Select)`
  width: 20rem;
  height: auto;
  margin-right: 2rem;
  font-size: 0.85rem;
`;

const CompaniesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  // grid-column-gap: 0rem;
  // grid-row-gap: 1.2rem;
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CardWapper = styled.div`
  margin: 0.6rem 0rem 0.6rem 0rem; //top right bottom left
  width: 22rem;
  height: 22rem;
  border-radius: 1rem;
  border-style: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  display: grid;
  grid-template-areas:
    "head"
    "content"
    "bottom";
  grid-template-rows: 2.8fr 3fr 1.5fr;
  grid-template-columns: 1fr;
  &:hover {
    box-shadow: 0rem 0rem 0.3rem 0.1rem #c0c0c0;
  }
`;

const CardHead = styled.div`
  grid-area: head;
  display: flex;
`;

const HeadImageWapper = styled.div`
  display: block;
  text-align: center;
  align-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  margin: 0.9rem;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: #c0c0c0;
  border-width: 1px;
`;

const HeadImage = styled.img`
  border-radius: 0.5rem;
  margin: 0rem 0rem;
  width: 100%;
  object-fit: contain;
`;

const HeadText = styled.div`
  // height: 8.5rem;
  padding-top: 2.1rem;
  width: 15rem;
`;

const ComName = styled.div`
  padding-left: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

const ComSummaries = styled.ul`
  display: flex;
  flex-flow: row wrap;
`;

const FirstSummary = styled.li`
  margin-left: -2rem;
  list-style: none;
  margin-right: 1.4rem;
`;

const Summary = styled.li`
  margin-right: 1.4rem;
`;

const CardContent = styled.div`
  grid-area: content;
`;

const ComIntro = styled.div`
  line-height: 1.5rem;
  font-size: 0.9rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
`;

const CardBottom = styled.div`
  grid-area: bottom;
`;

const HomeButton = styled.button`
  margin-left: 0.9rem;
  font-size: 1rem;
  color: #132bb1;
  padding: 0.5rem 0.7rem;
  border-style: solid;
  border-radius: 0.4rem;
  border-color: #c0c0c0;
  border-width: 1px;
  background-color: white;
  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;

const CareerButton = styled.button`
  margin-left: 0.6rem;
  font-size: 1rem;
  color: #132bb1;
  padding: 0.5rem 0.7rem;
  border-width: 0px;
  background-color: white;
  display: inline-flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const PaginateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #17b26a;
`;
