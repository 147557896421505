import Popup from "reactjs-popup";
import styled from "styled-components";

function MsgPopup(open, closeModal, message) {
  return (
    <div>
      <StyledPopup open={open} closeOnDocumentClick onClose={closeModal}>
        <ThanksWrapper>
          <Thanks>{message}</Thanks>
          <CloseButton onClick={closeModal}>关闭</CloseButton>
        </ThanksWrapper>
      </StyledPopup>
    </div>
  );
}

export default MsgPopup;

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }
  &-content {
  }
`;

const ThanksWrapper = styled.div`
  width: 20rem;
  height: 10rem;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #c0c0c0;
`;

const Thanks = styled.div`
  margin-top: 3rem;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  bottom: 1rem;
  left: 8.5rem;
  &:hover {
    background-color: #c0c0c0;
    cursor: pointer;
  }
`;
