import styled from "styled-components";
import CompanyList from "../components/company/CompanyList";

function Company() {
  return (
    <div>
      <CompanyList />
    </div>
  );
}

export default Company;

const Wrapper = styled.div`
  padding-top: 5rem;
  margin: 0 auto;
  width: 75rem;
`;

const Coming = styled.h2`
  margin-top: 0px;
  font-size: 6rem;
  box-sizing: border-box;
  min-width: 0px;
  line-height: 1.2;
  margin: 0px;
  background: linear-gradient(
    110.78deg,
    rgb(118, 230, 80) -1.13%,
    rgb(249, 214, 73) 15.22%,
    rgb(240, 142, 53) 32.09%,
    rgb(236, 81, 87) 48.96%,
    rgb(255, 24, 189) 67.94%,
    rgb(26, 75, 255) 85.34%,
    rgb(98, 216, 249) 99.57%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;
