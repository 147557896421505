import { useEffect, useState } from "react";

// @see https://x.com/DavidKPiano/status/1816100492570722320
const useRequest = (endPoint, onComplete, onError) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let ignore = false;
    setIsLoading(true);
    setHasError(false);
    setError(undefined);

    fetch(endPoint)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch");
        }
        return res.json();
      })
      .then((jsonData) => {
        if (!ignore) {
          setIsLoading(false);
          if (jsonData.success) {
            setData(jsonData);
            onComplete?.({ data: jsonData, isLoading: false, hasError: false });
          } else {
            throw new Error(jsonData.message || "Failed to fetch");
          }
        }
      })
      .catch((err) => {
        if (!ignore) {
          setIsLoading(false);
          setError(err);
          setHasError(true);
          setData(undefined);
          onError?.({
            data: undefined,
            isLoading: false,
            hasError: true,
            error: err,
          });
        }
      });

    return () => {
      ignore = true;
    };
  }, [endPoint]);

  return { data, isLoading, hasError, error };
};

export default useRequest;
