import React from "react";
import Home from "./Home";
import Company from "./Company";
import Message from "./Message";
import AboutPage from "./AboutPage";
import Navbar from "../components/navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function Pages() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/message" element={<Message />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Pages;
