// images
export const v2ex = {
  SOURCE_NAME: "v2ex",
  LOGO_URL: "./images/v2ex.png",
};

export const eleduck = {
  SOURCE_NAME: "eleduck",
  LOGO_URL: "./images/eleduck.png",
};

export const hn = {
  SOURCE_NAME: "hacker news",
  LOGO_URL: "./images/hn.png",
};

export const linkedin = {
  SOURCE_NAME: "linkedin",
  LOGO_URL: "./images/linkedin.png",
};

export const easynomad = {
  SOURCE_NAME: "easynomad",
  LOGO_URL: "./images/easynomad.png",
};

export const apiUrl = {
  // POST_LIST: "http://127.0.0.1:8088/api/posts/list?limit=10&",
  // MESSAGE_SAVE: "http://127.0.0.1:8088/api/message/save",
  // JOB_CATEGORY: "http://127.0.0.1:8088/api/posts/category",
  POST_LIST: "https://easynomad.cn/api/posts/list?limit=15&",
  MESSAGE_SAVE: "https://easynomad.cn/api/message/save",
  JOB_CATEGORY: "https://easynomad.cn/api/posts/category",
  COMPANY_LIST: "https://easynomad.cn/api/company/list?limit=9&",
  COMPANY_INDUSTRIES: "https://easynomad.cn/api/company/industries",
};

export const contractTypes = [
  { label: "远程兼职", value: "兼职" },
  { label: "远程全职", value: "全职" },
];
